// Generated by Framer (7f988a2)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {seRAOjR6P: {hover: true, pressed: true}};

const cycleOrder = ["seRAOjR6P", "Ra_gnzHaU"];

const serializationHash = "framer-E1pXR"

const variantClassNames = {Ra_gnzHaU: "framer-v-1rebcum", seRAOjR6P: "framer-v-tcy1xv"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "seRAOjR6P", "Variant 2": "Ra_gnzHaU"}

const getProps = ({click, height, id, width, ...props}) => { return {...props, gON4XULvR: click ?? props.gON4XULvR, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "seRAOjR6P"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, gON4XULvR, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "seRAOjR6P", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapz9a82p = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (gON4XULvR) {const res = await gON4XULvR(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-tcy1xv", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"seRAOjR6P"} onTap={onTapz9a82p} ref={ref ?? ref1} style={{backgroundColor: "var(--token-ca1d0ef1-67e9-4fc0-a717-bf79a57b2086, rgb(216, 219, 226))", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, ...style}} variants={{"seRAOjR6P-hover": {backgroundColor: "var(--token-2a06aa71-b5d8-494a-8c4b-12a1392cfa74, rgb(121, 124, 128))"}}} {...addPropertyOverrides({"seRAOjR6P-hover": {"data-framer-name": undefined}, "seRAOjR6P-pressed": {"data-framer-name": undefined}, Ra_gnzHaU: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-nmv908"} layoutDependency={layoutDependency} layoutId={"FlkHLxyYG"} style={{backgroundColor: "var(--token-bad8c0fb-1357-4b52-8a55-eed6fbc07df6, rgb(255, 255, 255))", borderBottomLeftRadius: 16, borderBottomRightRadius: 16, borderTopLeftRadius: 16, borderTopRightRadius: 16, rotate: 90}} variants={{"seRAOjR6P-pressed": {rotate: 135}, Ra_gnzHaU: {rotate: 135}}}/><motion.div className={"framer-191xhdr"} layoutDependency={layoutDependency} layoutId={"PpeiKOPQE"} style={{backgroundColor: "var(--token-bad8c0fb-1357-4b52-8a55-eed6fbc07df6, rgb(255, 255, 255))", borderBottomLeftRadius: 16, borderBottomRightRadius: 16, borderTopLeftRadius: 16, borderTopRightRadius: 16, rotate: 0}} variants={{"seRAOjR6P-pressed": {rotate: 45}, Ra_gnzHaU: {rotate: 45}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-E1pXR.framer-8pz03s, .framer-E1pXR .framer-8pz03s { display: block; }", ".framer-E1pXR.framer-tcy1xv { cursor: pointer; height: 38px; overflow: visible; position: relative; width: 38px; }", ".framer-E1pXR .framer-nmv908, .framer-E1pXR .framer-191xhdr { flex: none; height: 20px; left: calc(50.00000000000002% - 3px / 2); overflow: visible; position: absolute; top: calc(50.00000000000002% - 20px / 2); width: 3px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 38
 * @framerIntrinsicWidth 38
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Ra_gnzHaU":{"layout":["fixed","fixed"]},"BUrBAzmul":{"layout":["fixed","fixed"]},"mNjGQg6Ov":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"gON4XULvR":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerVVQV0DD7h: React.ComponentType<Props> = withCSS(Component, css, "framer-E1pXR") as typeof Component;
export default FramerVVQV0DD7h;

FramerVVQV0DD7h.displayName = "FAQ Button";

FramerVVQV0DD7h.defaultProps = {height: 38, width: 38};

addPropertyControls(FramerVVQV0DD7h, {variant: {options: ["seRAOjR6P", "Ra_gnzHaU"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, gON4XULvR: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerVVQV0DD7h, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})